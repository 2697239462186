import React from 'react'
import AppRoutes from './router' // Import the routes from router.js
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  return (
    <>
      <ToastContainer />
      <AppRoutes />
    </>
  )
}

export default App
